import { PaymentMethod } from '../actions/giftActions';
import math from 'math-expression-evaluator';
import xolaUrl from '../utils/xolaUrl';
import config from '../config';
import { roundCurrency } from '../utils/currency';
import _ from 'lodash';
import { getLanguageCode } from '../localize';

const AUTHORIZE_NET_CIM = 'authorize_net_cim';

export const getSellerLogoUrl = state => {
    if (state.seller.id && state.seller?.picture?.src) {
        return xolaUrl(`${state.seller.picture.src}`);
    }

    return null;
};

export const getBookingFeeAmount = (state, baseAmount, quantity = 1) => {
    const bookingFee = _.find(state.seller.partnerFeeFormulas, { source: state.gift.source, type: 'traveler' });

    if (bookingFee) {
        if (_.isUndefined(bookingFee.amount)) {
            // This is a V1 fee with percentage variables and such
            const formula = bookingFee.formula
                .replace('%amount%', baseAmount)
                .replace('%quantity%', quantity)
                .replace('%product_count%', state.gift.items.length);
            let feeAmount = math.eval(formula);

            if (feeAmount !== null) {
                feeAmount = roundCurrency(feeAmount, getCurrency(state));
            }
            feeAmount = bookingFee.max && feeAmount > bookingFee.max ? bookingFee.max : feeAmount;
            return feeAmount;
        } else {
            // This is the new V2 booking fee style
            const multiplier = bookingFee.amountType === 'percent' ? 0.01 : 1;
            let feeAmount = multiplier * bookingFee.amount * (_.isFinite(baseAmount) ? baseAmount : 0);
            feeAmount = bookingFee.max && feeAmount > bookingFee.max ? bookingFee.max : feeAmount;
            return feeAmount;
        }
    }

    return 0;
};

export const isCaptchaEnabled = state => {
    return !state.user && config.recaptcha.siteKey && state.seller.preferences.gift.captchaEnabled;
};

export const isThreeDSecureEnabled = state => {
    return !state.user && _.get(state.seller, 'preferences.payment.threeDSecureEnabled', false);
};

export const isShowGiftDemographicsEnabled = state => {
    return _.get(state.seller, 'preferences.gift.showDemographics', false);
};

export const getPaymentTokenization = seller => {
    return _.get(seller, 'preferences.paymentTokenization', { enabled: true, stripeElementsEnabled: true });
};

export const getDefaultGateway = seller => {
    return _.get(seller, 'defaultGateway', { publicKey: null });
};

export const getEnforcedPostalCode = state => {
    return state.seller.preferences.payment.enforcePostalCode || false;
};

export const getOtherPaymentMethods = seller => {
    const paymentMethods = [
        {
            id: PaymentMethod.OTHER,
            name: 'Other',
            comment: { title: 'Comment' },
        },
    ];

    const customPaymentMethods = (seller.paymentMethods || []).filter(customPaymentMethod => {
        return _.isNil(customPaymentMethod.deletedAt);
    });

    paymentMethods.push(...customPaymentMethods);

    return paymentMethods;
};

export const canUseEMVPayment = state => {
    return state.user && state.payment.device;
};

export const isConversionTrackingEnabled = state => {
    return _.get(state.seller, 'preferences.conversionTrackings', []).some(
        conversionTracking => conversionTracking.enabled,
    );
};

export const allowMultiItemGifts = state => {
    return _.get(state.seller, 'preferences.gift.allowMultiItemGifts', false);
};

export const getCurrency = state => {
    return state.seller.currency || 'USD';
};

export const isLanguageCodeSupported = (state, languageCode) => {
    const { supportedLanguages = [] } = state.seller.preferences.language;
    return supportedLanguages.some(language => getLanguageCode(language.locale) === languageCode);
};

export const isAuthorizeNetPaymentGateway = seller => {
    return getDefaultGateway(seller).type === AUTHORIZE_NET_CIM;
};