import { FeeScopeModifier, FeeScope } from '../actions/feeActions';
import { ProductObject } from '../actions/productActions';
import { getBookingFeeAmount } from './sellerSelectors';
import { DiscountAmountType } from './productsSelector';
import { PaymentMethod } from '../actions/giftActions';
import { getGiftItemProduct } from './giftSelector';
import _ from 'lodash';

export const getFeeAmount = (baseAmount, fee, quantity = 1) => {
    switch (fee.amountType) {
        case DiscountAmountType.ABSOLUTE:
            return fee.scope === FeeScope.PERSON ? fee.amount * quantity : fee.amount;
        case DiscountAmountType.PERCENT:
            return baseAmount * (fee.amount / 100);
        case DiscountAmountType.OVERRIDE:
            return baseAmount - fee.amount;
        default:
            return baseAmount;
    }
};

export const getProductFees = (state, product, type = 'tax') => {
    const { fees } = state;

    if (product && product.experience) {
        return fees.filter(fee => {
            const inScope = fee.scope !== FeeScope.ADDON && fee.scopeModifier !== FeeScopeModifier.ADDON_ONLY;
            return (
                inScope &&
                (fee.all || fee.experiences.indexOf(product.experience.id) >= 0) &&
                fee.productType !== ProductObject.MEMBERSHIP &&
                fee.type === type
            );
        });
    }

    return [];
};

export const getProductFeeAmount = (state, product, baseAmount, includeBookingFee = true, quantity = 1) => {
    let fees = getProductFees(state, product, 'fee');
    let feeAmount = _.sumBy(fees, fee => getFeeAmount(baseAmount, fee, quantity));
    const baseAmountWithFee = baseAmount + feeAmount;

    // Get the taxes which is fee in v1
    let taxes = getProductFees(state, product);
    let demographicOnlyTaxes = taxes.filter(tax => {
       return tax.scopeModifier && tax.scopeModifier === FeeScopeModifier.EXCLUDE_ADDON;
    });
    let otherTaxes = taxes.filter(tax => {
        return !tax.scopeModifier || tax.scopeModifier !== FeeScopeModifier.EXCLUDE_ADDON;
    });
    // For demographic only taxes, we need to calculate the tax based on base amount
    feeAmount += _.sumBy(demographicOnlyTaxes, tax => getFeeAmount(baseAmount, tax, quantity));
    // For any other scope modifier, we need to calculate the tax based on base amount with any v2 fees applied
    feeAmount += _.sumBy(otherTaxes, tax => getFeeAmount(baseAmountWithFee, tax, quantity));

    const orderAmountIncludesPartnerFee = _.find(state.seller.partnerFeeFormulas, {
        orderAmountIncludesPartnerFee: true,
    });
    const isCCPayment = _.includes([PaymentMethod.CREDIT_CARD, PaymentMethod.INTENT], state.gift.payment.method);
    const hasBookingFees = isCCPayment || orderAmountIncludesPartnerFee;
    if (includeBookingFee && hasBookingFees) {
        if (product && product.experience) {
            baseAmount += feeAmount;
        }
        return feeAmount + getBookingFeeAmount(state, baseAmount, quantity);
    }

    return feeAmount;
};

export const getApplicableGiftItemFees = (state, giftItem) => {
    const { fees, gift } = state;

    const product = getGiftItemProduct(state, gift.items.indexOf(giftItem));

    if (product && product.object === ProductObject.EXPERIENCE) {
        return fees.filter(fee => {
            return fee.all || fee.experiences.indexOf(giftItem.product.id) >= 0;
        });
    }

    return [];
};

export const getGiftItemFeeAmount = (state, giftItem) => {
    const applicableFees = getApplicableGiftItemFees(state, giftItem);
    return _.sumBy(applicableFees, fee => getFeeAmount(giftItem.baseAmount, fee, giftItem.quantity));
};
