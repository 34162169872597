import {
    ADD_GIFT_ITEM,
    UPDATE_GIFT_ITEM,
    REMOVE_GIFT_ITEM,
    REMOVE_UNCOMPLETED_GIFT_ITEMS,
    SAVE_GIFT_SUCCEEDED,
    SAVE_GIFT_REQUESTED,
    UPDATE_GIFT,
    CHANGE_PAYMENT_METHOD,
    PaymentMethod,
    GiftSource,
    PaymentIntentMethod,
    GiftItemStatus,
    RELEASE_GIFT_SUCCEEDED,
    SET_GIFT_ERROR,
    REMOVE_CARD,
} from '../actions/giftActions';

import { READ_CREDIT_CARD, ADD_PHYSICAL_GIFT_ITEM } from '../actions/socketActions';
import { BOOTSTRAP, BootstrapContext } from '../actions/bootstrapActions';
import { GiftAmountProduct } from '../actions/productActions';
import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

export const initialState = {
    items: [],
    payment: { method: PaymentMethod.CREDIT_CARD, comment: '' },
    source: GiftSource.CHECKOUT,
    error: null,
};

const giftReducer = createReducer(initialState, {
    [UPDATE_GIFT](state, { gift }) {
        _.merge(state, gift);
    },

    [ADD_GIFT_ITEM](state, { giftItem }) {
        state.items.push(giftItem);
    },

    [CHANGE_PAYMENT_METHOD](state, { method, isThreeDSecureEnabled }) {
        state.payment.method = method;
        let itemStatus;
        if (method === PaymentMethod.INTENT) {
            state.payment.paymentIntentMethods = isThreeDSecureEnabled
                ? [PaymentIntentMethod.CARD_NOT_PRESENT]
                : [PaymentIntentMethod.CARD_PRESENT];
            itemStatus = GiftItemStatus.STATUS_HOLD;
        } else {
            state.payment.paymentIntentMethods = null;
            itemStatus = GiftItemStatus.STATUS_ACCEPTED;
        }
        state.items.forEach((item, index) => {
            _.assign(state.items[index], { status: itemStatus });
        });
    },

    [UPDATE_GIFT_ITEM](state, { giftItemIndex, giftItem }) {
        _.assign(state.items[giftItemIndex], giftItem);
    },

    [REMOVE_GIFT_ITEM](state, { giftItemIndex }) {
        state.items.splice(giftItemIndex, 1);
    },

    [REMOVE_UNCOMPLETED_GIFT_ITEMS](state) {
        state.items = state.items.filter(giftItem => {
            return giftItem.recipientName && giftItem.baseAmount > 0;
        });
    },

    [SAVE_GIFT_REQUESTED](state, { gift }) {
        _.merge(state, gift);
    },

    [SAVE_GIFT_SUCCEEDED](state, { gift }) {
        _.merge(state, gift);
    },

    [RELEASE_GIFT_SUCCEEDED](state, { gift }) {
        return gift;
    },

    [READ_CREDIT_CARD](state, { number, name }) {
        state.payment.card.number = number;
        state.payment.card.billingName = name;
    },

    [ADD_PHYSICAL_GIFT_ITEM](state, { data }) {
        state.items.push({
            product: GiftAmountProduct,
            recipientName: '',
            recipientEmail: '',
            message: '',
            sendToCustomer: false,
            sendToRecipient: true,
            quantity: 1,
            code: data.code,
            baseAmount: parseFloat(data.value),
        });
    },

    [BOOTSTRAP](state, { context }) {
        const isSellerContext = context === BootstrapContext.SELLER || context === BootstrapContext.PHYSICAL_GIFT_CARD;
        state.source = isSellerContext ? GiftSource.OFFICE : GiftSource.CHECKOUT;
    },

    [SET_GIFT_ERROR](state, { error }) {
        state.error = error;
    },

    [REMOVE_CARD](state) {
        if (state.payment && state.payment.card) {
            delete state.payment.card;
        }
    },
});

export default giftReducer;
